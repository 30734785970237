import zIndex from "@mui/material/styles/zIndex";
import "./pages.css"
import logo from "../assets/img/logo.svg"
import whitepaper from '../assets/pdf/whitepaper.pdf'
const AboutUs = () => {

    return (
        <section className="mx-auto w-full bg-toden pb-12" id="about">
            <div className="mx-auto max-w-7xl px-4 md:px-10 lg:px-20 pt-3 pb-6 lg:pb-12">
                <div className="flex flex-col lg:flex-row">
                    <div className="z-10">
                        <img alt="Joe Toden" src={logo} className="!w-32 md:!w-80 lg:!w-[607px] -mb-4 md:!-mb-20 lg:mb-0" />
                    </div>
                    <div className="flex flex-col items-start bg-white bg-opacity-20 rounded-3xl -mt-8 pt-10 px-4 md:-mt-10 md:mx-10 md:px-8 lg:mt-36 lg:-ml-28 lg:pl-20">
                        <p className="about-font outline-shadow-black mt-0 md:mt-12">ABOUT US</p>
                        <p className="mb-10 text-gray-700 nunito text-left">Toden is not just another meme coin but a token with a mission - to help a tired and confused Joe transition to his new home.<br />Join the cause, help retire Joe, help make the world a safer place</p>
                        <a 
                            href={whitepaper} 
                            target="_blank" 
                            className="white-paper mb-8 !border-gray-500 w-96 !text-base lg:!text-2xl text-orange-500"
                        >
                            WHITEPAPER
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AboutUs;
