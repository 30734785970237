import React, { useMemo } from 'react';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { WalletProvider } from "@solana/wallet-adapter-react";
import { PhantomWalletAdapter } from "@solana/wallet-adapter-wallets";
import { WalletConnectProvider } from "./providers/WalletConnectProvider";

import Navbar from './layouts/navbar';
import PresalePart from "./pages/presalePart"
import Claim from './pages/claim';
import ThemeContext from './context/themeContext';

import './App.css';
import './font/BLOMBERG.ttf'
import SOL from "./assets/img/sol.svg"
import USDT from "./assets/img/usdt.png"
import Tokenomics from './pages/tokenomics';
import HowTo from './pages/howTo';
import RoadMap from './pages/roadmap';
import TheGame from './pages/game';
import Footer from './pages/footer'
import Description from './pages/description';
import AboutUs from './pages/aboutus';

function App() {

  const tokens = [
    { ft: "SOL", icon: SOL },
    { ft: "USDT", icon: USDT },
  ];
  return (
    <div className="App bg-center bg-cover bg-toden">
      <ThemeContext.Provider value={tokens}>
        <WalletConnectProvider>
          <Navbar />
          <Description />
          <AboutUs />
          <PresalePart />
          <HowTo />
          <RoadMap />
          <TheGame />
          <Tokenomics />
          <Claim />
          <Footer />
          <ToastContainer autoClose={3000} draggableDirection="x" toastStyle={{ backgroundColor: "#05bfc4", color: "white" }} />
        </WalletConnectProvider>
      </ThemeContext.Provider>
    </div>
  );
}

export default App;
