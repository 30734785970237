
import coming from "../assets/img/coming.png"
import logo from "../assets/img/logo.svg"

const TheGame = () => {
    return (
        <section className="mx-auto w-full bg-toden py-8 lg:py-16" id="game">
            <div className="mx-auto text-center p-4">
                <div className="uppercase text-orange-500 text-[40px] md:text-[60px] outline-shadow-black">toden the game</div>
                <div className="w-60 sm:w-72 lg:w-[25rem] mx-auto">
                    <img src={coming} alt="" className="absolute left-[45%] sm:left-[60%] md:left-[58%] lg:left-[55%] xl:left-[53%] w-[10rem] md:w-[16rem] -mt-12" />
                    <img src={logo} alt="" className="w-52 h-52 md:w-96 md:h-96 lg:w-[28rem] lg:h-[28rem] mx-auto mt-12 -ml-10 sm:ml-0" />
                </div>
            </div>
        </section>
    );
}

export default TheGame;
