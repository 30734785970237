import "./pages.css"

const Description = () => {

    return (
        <section className="bg-yellow-100 w-full">
            <div className="mx-auto max-w-7xl">
                <p className="w-full flex items-center justify-start bg-yellow-100 px-4 py-3 md:px-8 md:py-6 lg:py-10 text-left description-font">Joe was tired. Tired of politics, tired of the endless meetings, and tired of maintaining the charade. This was Joe Toden, and he was done.</p>
                <img alt="" src="assets/img/description.png" className="w-full" />
                <p className="flex w-full items-center justify-start bg-yellow-100 px-4 py-3 md:px-8 md:py-6 lg:py-10 text-left description-font">Joe's eyes wandered over the green grass, his mind a haze of forgotten passwords and misremembered names, His reign had been long, marked by corruption, questionable decisions and profitable, yet shady, deals. A retirement home, a place where he could relax and forget the complexities of the political world, beckoned him.</p>        
            </div>      
        </section>
    );
}

export default Description;
