import tokenomicsLg from "../assets/img/tokenomics-lg.webp"
import tokenomicsMd from "../assets/img/tokenomics-md.webp"
import tokenomicsSm from "../assets/img/tokenomics-sm.webp"

const Tokenomics = () => {
    return (
        <section className="mx-auto w-full bg-toden py-8" id="tokenomics">
            <div className="container mx-auto text-center p-4">
                <div className="uppercase text-dark-orange text-[40px] md:text-[60px] outline-shadow-black">Tokenomics</div>
                <div className="hidden lg:block mx-auto lg:w-4/5 2xl:w-2/3 text-center">
                    <img src={tokenomicsLg} className="mx-auto w-full" alt="Tokenomics" />
                </div>
                <div className="hidden sm:block lg:hidden mx-auto w-full text-center">
                    <img src={tokenomicsMd} className="mx-auto w-full" alt="Tokenomics" />
                </div>
                <div className="block sm:hidden mx-auto w-full text-center">
                    <img src={tokenomicsSm} className="mx-auto w-full" alt="Tokenomics" />
                </div>
                </div>
                <div className="w-full max-w-4xl mx-auto p-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-4 nunito text-white">
                    <div className="flex items-center justify-start uppercase text-lg bg-white bg-opacity-20 rounded-lg py-4 px-4 border-l-[10px] border-[#47FFFF]">30% pre-sale</div>
                    <div className="flex items-center justify-start uppercase text-lg bg-white bg-opacity-20 rounded-lg py-4 px-4 border-l-[10px] border-[#F723E2]">25% liquidity</div>
                    <div className="flex items-center justify-start uppercase text-lg bg-white bg-opacity-20 rounded-lg py-4 px-4 border-l-[10px] border-[#1483EA]">5% team</div>
                    <div className="flex items-center justify-start uppercase text-lg bg-white bg-opacity-20 rounded-lg py-4 px-4 border-l-[10px] border-[#EEDD44]">10% seed investors</div>
                    <div className="flex items-center justify-start uppercase text-lg bg-white bg-opacity-20 rounded-lg py-4 px-4 border-l-[10px] border-[#A20FBB]">10% marketing</div>
                    <div className="flex items-center justify-start uppercase text-lg bg-white bg-opacity-20 rounded-lg py-4 px-4 border-l-[10px] border-[#3EFFD1]">20% partnerships and giveways</div>
                </div>
            </div>
        </section> 
    )
}

export default Tokenomics;
