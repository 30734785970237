import { PublicKey } from "@solana/web3.js";

export const PRESALE_PROGRAM_PUBKEY = new PublicKey(
  "CRVumvxFWbwVrHzPgtPCXghreBH5vqUPSDajUnhuAsvs"
);

export const TOKEN_PUBKEY = new PublicKey(
  "2sL9QiGqtFFX1nhFutqDUK9hvbSTpZRmyuVoKgP5F5Jy"
);

export const PRESALE_SEED = "CLUB_PRESALE_SEED";
export const USER_SEED = "CLUB_USER_SEED";
export const PRESALE_ID = 2;

export const PRESALE_AUTHORITY = new PublicKey(
  "CsWvHhEfvJrtEtgYBiLadsKti24EGa21dwUaxNutsDq1"
);

export const TOKEN_PRESALE_HARDCAP = 900000000; // 900000000 tokens in mainnet
export const PRICE_PER_TOKEN = 0.004; // $
export const PRICE_DECIMAL = 8

export const BUYER_SOFTCAP = 0; // sol
export const BUYER_HARDCAP = 10000; // sol
export const BUYER_TOKEN_HARDCAP = 500000000; // token

export const TOKEN_DECIMAL = 9;

export const SOL_TOKEN_PUBKEY = new PublicKey ("So11111111111111111111111111111111111111112")
export const USDT_TOKEN_PUBKEY = new PublicKey('Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB') // mainnet: Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB devnet: 2zkLjbyshBC3h8jrwNsQjSdEHGrD6L35VLhxPsGLqa59

export const SOL_PRICEFEED_ID = new PublicKey('H6ARHf6YXhGYeQfUzQNGk6rDNnLBQKrenN712K4AQJEG')  // mainnet: H6ARHf6YXhGYeQfUzQNGk6rDNnLBQKrenN712K4AQJEG, devnet: J83w4HKfqxwcq3BEMMkPFSppX3gqekLyLJBexebFVkix

export const CURRENT_ROUND = 2

export const START_TIME = new Date("2024-09-21T00:00:00Z")
export const END_TIME = new Date("2024-10-11T00:00:00Z")