import DescriptionPart from "../components/descriptionPart";
import PresaleCard from "../components/presaleCard";
import {
    useWallet,
} from "@solana/wallet-adapter-react";
import {
    PRESALE_AUTHORITY,
    TOKEN_PUBKEY,
    USDT_TOKEN_PUBKEY,
    SOL_PRICEFEED_ID,
    TOKEN_PRESALE_HARDCAP
} from "../constants"

import usePresale from "../hooks/usePresale";

import "./pages.css"

const PresalePart = () => {

    const { publicKey } = useWallet();
    const {
        createPresale,
        depositToken,
        updatePresale,
        withdrawSol,
        withdrawToken,
    } = usePresale();

    const onCreatePresale = async () => {
        await createPresale();
    };

    const onDepositToken = async (depositingToken, pythAccount, amount) => {
        await depositToken(depositingToken, pythAccount, amount);
    };

    const onWithdrawToken = async (withdrawingToken) => {
        await withdrawToken(withdrawingToken);
    };

    const onUpdatePresale = async () => {
        await updatePresale();
    };

    const onWithdrawSol = async () => {
        await withdrawSol();
    };

    return (

        <section className="mx-auto w-full bg-toden py-10">

            {
                publicKey && publicKey.toBase58() === PRESALE_AUTHORITY.toBase58() &&
                <div className="container mx-auto display-flex justify-content-center align-items-center p-4">
                    <div className="grid grid-cols-4 items-center gap-2 mb-5">
                        <button
                            className="px-5 py-2 bg-[#d00711] rounded-full text-[#eff3f6] font-inter text-sm font-bold"
                            onClick={onCreatePresale}
                        >
                            Create Presale
                        </button>
                        <button
                            className="px-5 py-2 bg-[#d00711] rounded-full text-[#eff3f6] font-inter text-sm font-bold"
                            onClick={() => onDepositToken(TOKEN_PUBKEY, SOL_PRICEFEED_ID, TOKEN_PRESALE_HARDCAP)}
                        >
                            Deposit MintToken
                        </button>
                        <button
                            className="px-5 py-2 bg-[#d00711] rounded-full text-[#eff3f6] font-inter text-sm font-bold"
                            onClick={onUpdatePresale}
                        >
                            Update Presale
                        </button>
                        <button
                            className="px-5 py-2 bg-[#d00711] rounded-full text-[#eff3f6] font-inter text-sm font-bold"
                            onClick={onWithdrawSol}
                        >
                            Withdraw Sol
                        </button>
                        <button
                            className="px-5 py-2 bg-[#d00711] rounded-full text-[#eff3f6] font-inter text-sm font-bold"
                            onClick={() => onWithdrawToken(TOKEN_PUBKEY)}
                        >
                            Withdraw MintToken
                        </button>
                        <button
                            className="px-5 py-2 bg-[#d00711] rounded-full text-[#eff3f6] font-inter text-sm font-bold"
                            onClick={() => onWithdrawToken(USDT_TOKEN_PUBKEY)}
                        >
                            Withdraw USDTToken
                        </button>
                    </div>
                </div>
            }

            <div className="container mx-auto p-4">
                <div className="flex flex-col-reverse lg:flex-row -mx-2" id="presalepart">
                    <PresaleCard />
                    <DescriptionPart />
                </div>
            </div >
        </section>
    );
}

export default PresalePart;