import { useRef } from "react";

import "./layouts.css"
import { toast } from "react-toastify";
import { useWallet } from "@solana/wallet-adapter-react";

import logo from '../assets/img/logo.svg'
import abstract from "../assets/img/abstract.svg"
import times from "../assets/img/times.svg"

const Navbar = () => {

    const { select, wallets, publicKey, disconnect } = useWallet();
    const ref = useRef()

    const onWalletConnect = () => {
        if (!publicKey) {
            const installedWallets = wallets.filter(
                (wallet) => wallet.readyState === "Installed"
            );
            if (installedWallets.length <= 0) {
                toast.warning("Phantom wallet is not installed yet.");
                return;
            }
            select(wallets[0].adapter.name);
        } else {
            disconnect();
        }
    };

    const openNavigation = () => {
        ref.current.style.right = "0px"
    }

    const closeNavigation = () => {
        ref.current.style.right = "-208px"
    }

    return (
        <section className="mx-auto w-full bg-toden">
            <header className="relative inset-x-0 top-0 z-50 mx-auto max-w-7xl">
                <div className="px-4 lg:h-[100px] flex flex-col lg:flex-row gap-2 items-center justify-between">
                    <div className="flex flex-row w-full lg:w-fit justify-between py-5">
                        <div className="h-10 flex flex-row items-center gap-2.5">
                            <img src={logo} alt="" />
                            <p className="text-xl font-semibold text-orange">TODEN</p>
                        </div>
                        <img src={abstract} alt="" className="lg:hidden" onClick={openNavigation}/>
                    </div>
                    <div className="hidden lg:block p-px overflow-hidden rounded-3xl">
                        <div className="w-full h-full flex flex-row gap-[40px] items-center justify-center">
                            <a href="#about" className="text-xl font-medium cursor-pointer">ABOUT US</a>
                            <a href="#buy" className="text-xl font-medium cursor-pointer">HOW TO BUY</a>
                            <a href="#roadmap" className="text-xl font-medium cursor-pointer">ROADMAP</a>
                            <a href="#tokenomics" className="text-xl font-medium cursor-pointer">TOKENOMICS</a>
                        </div>
                    </div>
                    <button className="h-12 w-full lg:w-fit flex flex-row items-center justify-center rounded-3xl px-6 py-3 text-xl bg-gradient-to-b from-dark-orange to-[#F5B479] mb-5" onClick={onWalletConnect}>
                        {!publicKey ? `CONNECT  WALLET` : publicKey.toBase58().slice(0, 4) + " ... " + publicKey.toBase58().slice(-4)}
                    </button>
                    <div className="fixed lg:hidden h-full w-52 z-10 top-0 -right-52 bg-dark-gray overflow-x-hidden transition-all ease-in duration-500 flex flex-col rounded-tl-3xl rounded-bl-3xl py-6 gap-2" ref={ref}>
                        <a className="flex items-center justify-end px-4" onClick={closeNavigation}><img src={times} className=""/></a>
                        <a href="#about" className="flex items-center justify-start transition-all ease-in duration-300 uppercase px-4 w-[100px]">About</a>
                        <a href="#buy" className="flex items-center justify-start transition-all ease-in duration-300 uppercase px-4 min-w-[100px]">How To Buy</a>
                        <a href="#roadmap" className="flex items-center justify-start transition-all ease-in duration-300 uppercase px-4 min-w-[100px]">Roadmap</a>
                        <a href="#tokenomics" className="flex items-center justify-start transition-all ease-in duration-300 uppercase px-4 min-w-[100px]">Tokenomics</a>
                    </div>
                </div>
            </header>
        </section>
    );
}

export default Navbar;