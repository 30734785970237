import logo from "../assets/img/logo.svg"
import telegram from "../assets/img/telegram.svg"
import twitter from "../assets/img/twitter.svg"

const Footer = () => {
    return (
        <footer className="bg-footer">
            <div className="mx-auto max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8">
                <div className="flex justify-center items-center space-x-4 mb-8 text-2xl text-orange-500">
                    <img className="w-16 h-16" src={logo} alt="" />
                    <span className="uppercase">toden</span>
                </div>
                <nav className="-mb-6 columns-2 text-center sm:flex sm:justify-center sm:space-x-12" aria-label="Footer">
                    <div className="pb-6">
                        <a href="#about" className="text-base leading-6 text-white hover:text-orange-300">ABOUT US</a>
                    </div>
                    <div className="pb-6">
                        <a href="#buy" className="text-base leading-6 text-white hover:text-orange-300">HOW TO BUY</a>
                    </div>
                    <div className="pb-6">
                        <a href="#roadmap" className="text-base leading-6 text-white hover:text-orange-300">ROADMAP</a>
                    </div>
                    <div className="pb-6">
                        <a href="#tokenomics" className="text-base leading-6 text-white hover:text-orange-300">TOKENOMICS</a>
                    </div>
                </nav>
                <div className="mt-10 flex justify-center space-x-10 text-gray-300">
                    <a href="https://t.me/JoeTodenOfficial" target="_blank" className="flex flex-col items-center px-10">
                        <span className="sr-only">Telegram</span>
                        <img src={telegram} className="mb-4 w-12 h-12" alt="" />
                        <span className="text-xl font-semibold">Telegram</span><span className="uppercase text-lg">@joetoden</span>
                    </a>
                    <a href="https://x.com/joetoden" target="_blank" className="flex flex-col items-center px-10">
                        <span className="sr-only">Twitter</span>
                        <img src={twitter} className="mb-4 w-12 h-12" alt="" />
                        <span className="text-xl font-semibold">Twitter</span>
                        <span className="uppercase text-lg">@joetoden</span>
                    </a>
                </div>
                <p className="mt-10 text-center text-xs leading-5 text-gray-300">&copy;2024 Joe Toden, Inc. All rights reserved.</p>
            </div>
        </footer>
    )
}

export default Footer;
