
import "./components.scss"

import sitRoad from "../assets/img/sit-road.png"
import sitRoad1 from "../assets/img/site-road-1.png"

const DescriptionPart = () => {
    return (
        <div className="w-full lg:w-1/3 xl:w-1/2 px-2">
            <div className="p-4 lg:p-8 text-white">
                <img src={sitRoad} alt="" className="hidden lg:block lg:left-24 w-auto mx-auto"/>
                <img src={sitRoad1} alt="" className="block lg:hidden w-auto mx-auto"/>
            </div>
        </div>
    );
}

export default DescriptionPart;
