import step1 from "../assets/img/step-1.svg"
import step2 from "../assets/img/step-2.svg"
import step3 from "../assets/img/step-3.svg"
import step3Mirror from "../assets/img/step-3-mirror.svg"

const HowTo = () => {
    return (
        <section className="mx-auto w-full bg-toden py-8 mt-16" id="buy">
            <div className="container mx-auto p-4">
                <div className="mx-auto items-center text-center">
                    <div className="text-[40px] md:text-[60px] leading-[62.4px] tracking-tighter text-orange-500 outline-shadow-black">
                        How To Buy
                    </div>
                </div>
            </div>
            <div className="container mx-auto p-4">
                <div className="flex flex-col lg:flex-row justify-between mt-12">
                    <div className="relative mx-auto">
                        <img src={step1} alt="" className="absolute w-[230px] h-[250px]" />
                        <div className="px-10 py-20 rounded-[50%] bg-white bg-opacity-20 max-w-[416px] mt-[150px]">
                            <p className="text-yellow-400">Create a Wallet</p>
                            <p className="text-white">Download Phantom wallet from the app store or google play store. Desktop users download phantom extension from <a href="https://chrome.google.com/webstore/detail/phantom/bfnaelmomeimhlpmgjnjophhpkkoljpa" className="text-cyan-400" target="_blank">phantom.app</a>
                            </p>
                        </div>
                    </div>
                    <div className="relative block lg:hidden mx-auto">
                        <img src={step2} alt="" className="absolute left-auto right-[3rem] w-[230px] h-[250px]" />
                        <div className="px-10 py-20 rounded-[50%] bg-white bg-opacity-20 max-w-[416px] mt-[150px]">
                            <p className="text-yellow-400">Get some SOL / USDT</p>
                            <p className="text-white">Have some SOL or USDT in your wallet to exchange for $TODEN</p>
                        </div>
                    </div>
                    <div className="relative lg:mt-[200px] mx-auto">
                        <img src={step3} alt="" className="absolute left-1/3 w-[230px] h-[250px] hidden lg:block" />
                        <img src={step3Mirror} alt="" className="absolute w-[230px] h-[250px] block lg:hidden" />
                        <div className="px-10 py-20 rounded-[50%] bg-white bg-opacity-20 max-w-[416px] mt-[150px]">
                            <p className="text-yellow-400">Connect and Buy</p>
                            <p className="text-white">Enter the amount of SOL or USDT you want to spend and our platform will display the corresponding $TODEN token amount from current exchange rate.</p>
                        </div>
                    </div>
                    <div className="relative hidden lg:block mx-auto">
                        <img src={step2} alt="" className="absolute left-1/2 w-[230px] h-[250px]" />
                        <div className="px-10 py-20 rounded-[50%] bg-white bg-opacity-20 max-w-[416px] mt-[150px]">
                            <p className="text-yellow-400">Get some SOL / USDT</p>
                            <p className="text-white">Have some SOL or USDT in your wallet to exchange for $TODEN</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mx-auto p-4">
                <div className="text-center">
                    <a href="#presalepart">
                        <button className="uppercase text-gray-600 rounded-full py-4 px-16 bg-gradient-to-b from-orange-300 to-orange-600 mt-20 text-2xl nunito">buy $toden</button>
                    </a>
                </div>
            </div>
        </section>
    )
}

export default HowTo;
