
import roadmap from "../assets/img/roadmap.png"

const RoadMap = () => {
    return (
        <section className="mx-auto w-full bg-toden py-8 lg:py-12" id="roadmap">
            <div className="container mx-auto text-center p-4">
                <div className="uppercase text-orange-500 text-[40px] md:text-[60px] outline-shadow-black">roadmap</div>
                <div className="text-center">
                    <img src={roadmap} className="mx-auto" alt="Roadmap" />
                </div>
            </div>
        </section>
    );
}

export default RoadMap;


/*
        <div className="w-full mx-auto mt-12 lg:mt-24 px-8 lg:px-[80px] xl:px-[120px] 2xl:px-[200px]" id="roadmap">
            <div className="uppercase text-orange-500 text-[40px] md:text-[60px] outline-shadow-black">roadmap</div>
            <img src={roadmap} className="" alt="" />
        </div>
*/