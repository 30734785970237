import "./pages.css"
import {
    TOKEN_DECIMAL,
} from "../constants"
import usePresale from "../hooks/usePresale.js"
import { Icon, IconType } from "../components/icons";
import {numberWithCommas} from "../utils"

import logo from "../assets/img/logo.svg"

const Claim = () => {

    const { claimToken, transactionPending, buyAmount, claimedAmount } = usePresale();

    return (
        <section className="mx-auto w-full bg-toden pt-8 pb-16 lg:pb-24 xl:pb-32" id="claim">
            <div className="w-full max-w-3xl mx-auto p-4 text-center">
                <div className="text-[32px] md:text-[56px] leading-[62.4px] tracking-tight outline-shadow-black uppercase text-center text-white">
                    Claim your <span className="text-orange-500">$toden</span>
                </div>
                <div className="!text-lg text-dark-gray tracking-tight mt-4 nunito">Claim your "TODEN" tokens today and unlock exclusive benefits soon.</div>
            </div>
            <div className="w-full max-w-3xl mx-auto p-4 text-center">
                <div className="w-full bg-white bg-opacity-20 p-6 lg:p-9 rounded-3xl mt-6 lg:px-12">
                    <p className="text-gray-800 font-semibold nunito !text-xl">Your claimable amount</p>
                    <div className="h-9 flex flex-row items-center justify-center mt-6">
                        <img src={logo} className="w-10 h-10" alt="" />
                        <div className="!text-2xl ml-2 text-white nunito">{numberWithCommas(Number((buyAmount - claimedAmount)/(10 ** TOKEN_DECIMAL)).toFixed(2))}</div>
                    </div>
                    {transactionPending ? 
                        <div className="flex flex-row items-center justify-center mt-6">
                            <Icon type={IconType.LOADING} className="w-14 h-14" />
                        </div> : 
                        <button className="w-full h-12 rounded-2xl bg-gradient-to-b from-orange-300 to-orange-600 nunito !text-lg uppercase font-medium text-white mt-6" onClick={claimToken}>Claim now</button>
                    }
                </div>
            </div>
        </section>
    );
}

export default Claim;
