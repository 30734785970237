import React, { useState, useContext, useEffect, useCallback } from "react";
import {
    useWallet,
  } from "@solana/wallet-adapter-react";
import PrePayInput from "./prePayInput"
import PreReceiveInput from "./preReceiveInput"
import Stats from "../components/stats.js"
import { Icon, IconType } from "./icons";

import { toast } from "react-toastify";

import "./components.scss"
import CountItem from "./countItem"

import Countdown from "react-countdown";
import ThemeContext from '../context/themeContext';
import usePresale from "../hooks/usePresale.js"

import {
    SOL_PRICEFEED_ID,
    USDT_TOKEN_PUBKEY,
    PRICE_PER_TOKEN
} from "../constants";

import logo from "../assets/img/logo.svg"

const PresaleCard = () => {

    const { buyToken, depositToken, transactionPending, startTime, endTime, getPrice, curRound } = usePresale();
    const tokens = useContext(ThemeContext);
    const { publicKey } = useWallet();

    const [quoteAmount, setQuoteAmount] = useState(0);
    const [tokenAmount, setTokenAmount] = useState(0);
    const [dropIndex, setDropIndex] = useState(0);
    const [ratio, setRatio] = useState(1)
    const [balance, setBalance] = useState(0)
    const [canBuy, setCanBuy] = useState(true);

    useEffect(() => {
        const current = Date.now();
        if (startTime * 1000 < current && endTime * 1000 > current) {
            setCanBuy(true);
        } else {
            setCanBuy(false);
        }
    }, [startTime, endTime]);

    const _setRatio = useCallback(async () => {
        const price = await getPrice(tokens[dropIndex].ft)
        if (price) setRatio((Number(price) / PRICE_PER_TOKEN))
        // if (price) setRatio(parseInt(Number(price) / PRICE_PER_TOKEN))
        else setRatio(0)
    }, [dropIndex, publicKey])

    useEffect(() => {
        _setRatio()
    }, [_setRatio])

    useEffect(() => {
        setTokenAmount(quoteAmount * ratio)
    }, [quoteAmount, ratio])

    // useEffect(() => {
    //     setQuoteAmount(tokenAmount / ratio)
    // }, [tokenAmount])

    const onBuyToken = async () => {
        if (balance < quoteAmount) {
            toast.warning("Please check balance again.");
            return;
        }
        if (tokens[dropIndex].ft === "SOL") { buyToken(quoteAmount); }
        else {
            if (tokens[dropIndex].ft === "USDT") {
                depositToken(USDT_TOKEN_PUBKEY, SOL_PRICEFEED_ID, quoteAmount)
            }
        }
    };

    return (
        <div className="w-full lg:w-2/3 xl:w-1/2 px-2 flex flex-row justify-center lg:justify-start">
        <div className="w-full flex flex-col">
            <div className="w-full flex flex-col rounded-2xl bg-white bg-opacity-20 px-10 md:px-2 py-2 md:py-4">
                <div className="text-[14px] leading-[17px] tracking-wide uppercase text-left">
                    {Date.now() < startTime * 1000 && `Round ${curRound} Pre-Sale Starts In`}
                    {Date.now() >= startTime * 1000 && Date.now() < endTime * 1000 && `Round ${curRound} Pre-Sale Ends In`}
                    {Date.now() > endTime * 1000 && ""}
                </div>
                <div className="">
                    {endTime ? Date.now() < endTime * 1000 ? (
                        <Countdown
                            date={
                                Date.now() < startTime * 1000 ? startTime * 1000 : endTime * 1000
                            }
                            renderer={renderer}
                        />
                    ) : (
                        <span className="about-font outline-shadow-black">
                            {Number(curRound) === 4 ? "Presale completed" : `Round ${curRound} presale finished`}
                        </span>
                    ):<span className="about-font outline-shadow-black">
                        CONNECT YOUR WALLET
                    </span> 
                    }
                </div>
            </div>
            <div className="bg-white bg-opacity-20 rounded-2xl mt-3 px-4 py-6 md:px-9 md:py-11">
                <div className="h-10 flex flex-row items-center justify-center gap-2">
                    <img src={logo} alt="" />
                    <p className="text-4xl font-semibold text-white">TODEN</p>
                </div>
                <Stats />
                <PrePayInput
                    title="Amount you pay"
                    value={quoteAmount}
                    setValue={(val) => setQuoteAmount(val)}
                    dropIndex={dropIndex}
                    setDropIndex={(val) => setDropIndex(val)}
                    balance={balance}
                    setBalance={(val) => setBalance(val)}
                    transactionPending={transactionPending}
                />
                <PreReceiveInput 
                    title="Amount you receive"
                    value={tokenAmount}
                    setValue={(val) => setTokenAmount(val)}
                    transactionPending={transactionPending}
                />
                <div className="flex flex-col mt-10 nunito">
                    <div className="flex flex-row items-center justify-center text-[14px] font-normal leading-[16.94px] mt-2.5">
                        <div className="flex flex-row items-center mr-2">
                            <img src={logo} className="w-8 h-8 mr-1" alt="" />
                            <span>1 TODEN</span>
                        </div>
                        =
                        <div className="flex flex-row items-center ml-2">
                            {/* {tokens[dropIndex].ft === "SOL" && <img src='/assets/img/sol.svg' alt="" className="w-5 h-5" />}
                            {tokens[dropIndex].ft === "USDT" && <img src='/assets/img/usdt.png' alt="" className="w-5 h-5" />} */}
                            <img src='/assets/img/usdt.png' alt="" className="w-5 h-5" />
                            <span className="ml-1">${PRICE_PER_TOKEN}</span>
                        </div>
                    </div>
                </div>
            {(canBuy && !transactionPending) && (
                <button
                    className="bg-toden w-full h-14 flex flex-row items-center justify-center rounded-[40px] px-4 py-2 text-[#FFFFFF] text-lg font-semibold bg-transparent mt-10 uppercase border border-[#A4FCFF] nunito"
                    onClick={onBuyToken}
                >
                    Buy $Toden
                </button>
            )}
            {(canBuy && transactionPending) && 
                <div className="flex flex-row items-center justify-center mt-10 uppercase border border-[#A4FCFF] nunito">
                    <Icon type={IconType.LOADING} className="w-14 h-14" />
                </div>
            }
            {!canBuy && (
                <button
                    className="bg-toden w-full h-14 flex flex-row items-center justify-center rounded-[40px] px-4 py-2 text-[#FFFFFF] text-lg font-semibold bg-transparent mt-10 uppercase border border-[#A4FCFF] nunito"
                    disabled
                >
                    Buy $Toden
                </button>
            )}
            </div>
        </div>
        </div>
    )
}

const renderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
}) => {
    if (completed) {
        // Render a completed state
        return <Completionist />;
    } else {
        // Render a countdown
        return (
            <div className="flex flex-row justify-between mt-4">
                <CountItem title="DAYS" value={`${days>=10?days.toString():'0' + days.toString()}`}></CountItem>
                <div className="flex flex-row items-center text-[32px] font-normal leading-[38.73px]">:</div>
                <CountItem title="HRS" value={`${hours>=10?hours.toString():'0' + hours.toString()}`}></CountItem>
                <div className="flex flex-row items-center text-[32px] font-normal leading-[38.73px]">:</div>
                <CountItem title="MIN" value={`${minutes>=10?minutes.toString():'0' + minutes.toString()}`}></CountItem>
                <div className="flex flex-row items-center text-[32px] font-normal leading-[38.73px]">:</div>
                <CountItem title="SEC" value={`${seconds>=10?seconds.toString():'0' + seconds.toString()}`}></CountItem>
            </div>
        );
    }
};

export default PresaleCard;